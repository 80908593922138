import * as React from "react"
import { addPropertyControls, ControlType } from "framer"
import { motion } from "framer-motion"

/**
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function FramerButton(props) {
    const { horizontal, vertical, viaProperty } = props

    const cssContent = `
    #__framer-badge-container {
      ${
          horizontal === "left"
              ? "justify-content: flex-start !important;"
              : "justify-content: flex-end !important;"
      }
      ${vertical === "top" ? "top: 0 !important;" : "bottom: 0 !important;"}
    }
  `

    React.useEffect(() => {
        const styleSheet = document.createElement("style")
        styleSheet.type = "text/css"
        styleSheet.innerText = cssContent
        document.head.appendChild(styleSheet)

        const intervalId = setInterval(() => {
            const badgeContainer = document.querySelector(
                "#__framer-badge-container"
            )
            if (badgeContainer) {
                const framerLink = badgeContainer.querySelector(
                    "a[href='https://framer.com/']"
                )
                if (framerLink) {
                    framerLink.href = `https://framer.com/?via=${viaProperty}`
                    clearInterval(intervalId)
                }
            }
        }, 500)

        return () => {
            document.head.removeChild(styleSheet)
            clearInterval(intervalId)
        }
    }, [horizontal, vertical, viaProperty])

    return <motion.div></motion.div>
}

FramerButton.defaultProps = {
    horizontal: "left",
    vertical: "top",
    viaProperty: "lovi",
}

addPropertyControls(FramerButton, {
    horizontal: {
        type: ControlType.Enum,
        title: "Horizontal Position",
        options: ["left", "right"],
        optionTitles: ["Left", "Right"],
        defaultValue: "left",
    },
    vertical: {
        type: ControlType.Enum,
        title: "Vertical Position",
        options: ["top", "bottom"],
        optionTitles: ["Top", "Bottom"],
        defaultValue: "top",
    },
    viaProperty: {
        type: ControlType.String,
        title: "Ref code",
        defaultValue: "lovi",
    },
})
